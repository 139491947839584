<template>
  <div class="common-giftcodes-panel w-100">
    <div class="panel-heading d-flex align-items-center">
      <img src="../../../assets/images/icon/ticket.svg"/> <h4>{{ $t("General giftcodes") }}</h4>
    </div>
    <div class="panel-body">
      <div class="gift-codes row">
        <div v-for="(item, index) in items" :key="index" class="cols col-sm-6 col-md-4">
          <div class="code-item text-center">
            <div class="title-container">
              <div class="title">{{ item.name }}</div>
              <div class="code">{{ item.code }}</div>
            </div>
            <div class="buttons d-flex justify-content-between">
              <b-button class="btn-rounded" variant="outline-primary" @click="$showGiftCodeDetail(item.id)">
                {{ $t("View") }}
              </b-button>
              <div class="mr-2"></div>
              <b-button class="btn-rounded" variant="primary" @click="$useGiftCode(item)">{{ $t("Use") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!--      <SlickSlider :items="items" :arrows="false" :dots="false" :autoplay="true" :autoplay-speed="10000" :responsive="[{breakpoint: 500, settings: {slidesToShow: 2}}]"-->
      <!--                   :slides-to-show="3" :center-mode="true" center-padding="0">-->
      <!--        <template v-slot:item="slotProps">-->
      <!--          <div class="code-item text-center">-->
      <!--            <div class="title-container">-->
      <!--              <div class="title">{{ slotProps.item.name }}</div>-->
      <!--              <div class="code">{{ slotProps.item.code }}</div>-->
      <!--            </div>-->
      <!--            <div class="buttons d-flex justify-content-between">-->
      <!--              <b-button class="btn-rounded" variant="outline-primary" @click="$showGiftCodeDetail(slotProps.item.id)">-->
      <!--                {{ $t("View") }}-->
      <!--              </b-button>-->
      <!--              <div class="mr-2"></div>-->
      <!--              <b-button class="btn-rounded" variant="primary" @click="$useGiftCode(slotProps.item)">{{ $t("Use") }}-->
      <!--              </b-button>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </SlickSlider>-->
    </div>
  </div>
</template>

<script>
import SlickSlider from "@/components/sliders/SlickSlider";
export default {
  name: "GeneralGiftCodesPanel",
  components: {SlickSlider},
  props: ["items"]
}
</script>

<style scoped lang="scss">
.common-giftcodes-panel {
  background-color: #f0f6ff;
  .panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid #ded8d1;
    img {
      margin-right: 10px;
    }
    h4 {
      color: #6F6F6F;
      margin: 0;
      font-weight: 700;
      font-size: 20px;
    }
  }
  .panel-body {
    padding: 10px 10px;
    .slide-item, .gift-codes {
      padding: 0 !important;
      .code-item {
        margin: 0;
        padding: 10px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px dashed #d1d1d6;
        .title-container {
          display: block;
          min-height: 60px;
          .title {
            font-size: 13.5px;
            color: #7e7e7e;
            margin-bottom: 2px;
            display: -webkit-box;
            width: 100%;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
          }
          .code {
            font-weight: 600;
            font-size: 13.5px;
            color: #1a1a1a;
          }
        }
        .buttons {
          padding-top: 5px;
          button {
            flex: 1;
            font-size: 13.5px;
            padding-top: 8px;
            padding-bottom: 5px;
            &.btn-outline-primary {
              background-color: #fff7ec;
              border: 1px solid #ff9500;
              color: #ff9500;
            }

            &.btn-primary {
              border: 1px solid #ff9500;
              background: #ff9500;
            }
          }
        }
      }
    }
    .gift-codes {
      .code-item {
        margin-bottom: 10px;
      }
      &.row {
        margin-left: -5px;
        margin-right: -5px;
        .cols {
          padding: 0 5px;
        }
      }
    }
  }
}
@media all and (max-width: 576px) {
  .code-item {
    .title-container{
      min-height: auto !important;
    }
  }
}
</style>
