<template>
  <div class="page page-received-giftcodes">
    <b-row style="margin-top: -15px">
      <GeneralGiftCodesPanel v-if="generalCodes && generalCodes.length" :items="generalCodes"/>
    </b-row>

    <EventGiftCodesPanel v-if="eventCodes && eventCodes.length" :items="eventCodes"/>
    <LoadingPanel v-if="loading"/>
  </div>
</template>

<script>
import GeneralGiftCodesPanel from "@/components/panels/giftcode/GeneralGiftCodesPanel";
import EventGiftCodesPanel from "@/components/panels/giftcode/EventGiftCodesPanel";
import giftcodeService from "@/services/giftcodeService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "ReceivedGiftCodesPanel",
  components: {GeneralGiftCodesPanel, EventGiftCodesPanel},
  props: ["idGame"],
  data() {
    return {
      loading: false,
      generalCodes: [],
      eventCodes: [],
      take: 20,
      skip: 0,
      ended: false
    }
  },
  async created() {
    this.loading = true;
    const response = await giftcodeService.getReceivedCodes({
      idGame: this.idGame || 0,
      takeEvents: this.take
    });
    this.loading = false;
    if (!response)
    {
      await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      return;
    }

    if (response.error) {
      await dialogHelper.alert(response.message);
      return;
    }

    let codes = response.data;
    this.generalCodes = codes.generalCodes;
    codes = codes.eventCodes;
    codes.forEach(code => code.imageUrl = this.idGame && this.idGame > 1 ? code.type.imageUrl : code.game.iconUrl);
    const totalCodes = codes.length;
    this.eventCodes = codes;
    this.skip = totalCodes;
    this.ended = this.take > totalCodes;
  },
  mounted() {
    this.$bridge.$on("scrollToBottom", this.loadData);
  },
  beforeDestroy() {
    this.$bridge.$off("scrollToBottom", this.loadData);
  },
  methods: {
    async loadData() {
      if (this.loading || this.ended)
        return;

      this.loading = true;
      const response = await giftcodeService.getReceivedEventCodes({
        idGame: this.idGame || 0,
        take: this.take,
        skip: this.skip
      });
      this.loading = false;
      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      let codes = response.data;
      codes.forEach(code => code.imageUrl = this.idGame && this.idGame > 1 ? code.type.imageUrl : code.game.iconUrl);
      const totalCodes = codes.length;
      this.eventCodes = [...this.eventCodes, ...codes];
      this.skip += totalCodes;
      this.ended = this.take > totalCodes;
    }
  }
}
</script>