<template>
  <div class="event-giftcodes-panel">
    <b-row>
      <b-col sm="6" v-for="(item, index) in items" :key="index">
        <div class="gc-item d-flex align-items-center" @click="$showGiftCodeDetail(item.id)">
          <img :src="item.imageUrl.getUrl()" class="gc-icon" />
          <div class="gc-info">
            <div class="gc-title">{{item.name}}</div>
            <div class="gc-time">{{$toDateTimeString(item.startDate)}} - {{$toDateTimeString(item.endDate)}}</div>
            <div class="gc-total-received"><b-icon icon="person" class="mr-1"/>{{$t("{0} received", [item.totalReceived])}}</div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "EventGiftCodesPanel",
  props: ["items"]
}
</script>

<style scoped lang="scss">
  .event-giftcodes-panel {
    padding: 15px 0;
    
    .gc-item {
      padding: 10px;
      background-color: #f7f7f7;
      border-radius: 10px;
      margin-bottom: 15px;
      
      &:hover {
        cursor: pointer;
      }
      
      .gc-info {
        border-left: 1px dashed #bbb;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;
        
        &::before {
          content: "";
          background-color: #fff;
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute; 
          top: -20px;
          left: -10px;
        }

        &::after {
          content: "";
          background-color: #fff;
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute;
          bottom: -20px;
          left: -10px;
        }
        
        .gc-title {
          font-weight: 700;
          font-size: 15px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
        }

        .gc-time, .gc-total-received {
          color: #767676;
          font-size: 13px;
        }
      }
      
      .gc-icon {
        height: 50px;
        width: 50px;
        object-fit: contain;
        margin-right: 15px;
      }
      
      
    }
  }
</style>