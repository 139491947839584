<template>
  <div class="page page-received-giftcodes">
    <ReceivedGiftCodesPanel />
  </div>
</template>

<script>
import ReceivedGiftCodesPanel from "@/components/panels/giftcode/ReceivedGiftCodesPanel";
export default {
  name: "Received",
  components: {ReceivedGiftCodesPanel},
}
</script>